<template>
  <div>
    <TitleWrapper
      :filter-option="false"
      title="CHANGE_PHONE"
      tooltip-title="CHANGE_PHONE"
      :display-breadcrumb="true"
    />
    <div
      class="min-h-screen bg-bg-color-white pt-7 mt-7 px-3 md:px-7 border rounded-t-lg border-border-color-lighter"
    >
      <div class="mt-2 mb-6 w-full md:w-96">
        <PhoneNumber
          v-model="phoneNumber"
          title="Phone Number"
          :rules="'validNumber'"
          class="flex-1"
        />
      </div>

      <div class="md:inline-flex gap-2 text-sm rtl:flex-row-reverse">
        <div class="flex cursor-pointer items-center">
          <button
            class="mt-5 focus:outline-none focus:shadow-md font-normal md:ltr:text-base rtl:text-sm text-white font-rubik flex w-full md:w-40 py-2 justify-center primary-button"
          >
            Save Changes
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleWrapper from '@components/TitleWrapper.vue'
import PhoneNumber from '@components/UiElements/PhoneNumber.vue'

export default {
  components: {
    TitleWrapper,
    PhoneNumber,
  },
  data() {
    return {
      phoneNumber: '',
    }
  },
}
</script>
